import React, { lazy } from 'react';

const GLInterfacePolicyForm = lazy(() =>
  import('./GLInterfacePolicyForm').then(module => ({
    default: module.GLInterfacePolicyForm,
  })),
);

const glInterfacePolicyRoutes = [
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-static/gl-interface-policy`,
    },
    component: <GLInterfacePolicyForm />,
  },
];

export default glInterfacePolicyRoutes;

import React, { lazy } from 'react';

const GLBillExpenseForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLTransactionModule/GLBillExpense/GLBillExpenseForm'
  ).then(module => ({
    default: module.GLBillExpenseForm,
  })),
);

const glBillExpenseRoutes = [
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-dynamic/:associationType/setup`,
    },
    component: <GLBillExpenseForm />,
  },
];

export default glBillExpenseRoutes;

import React, { lazy } from 'react';

const GLExportListing = lazy(() =>
  import('./GLExportListing').then(module => ({
    default: module.GLExportListing,
  })),
);

const GLExportDetailsListing = lazy(() =>
  import('./GLExportDetailsListing').then(module => ({
    default: module.GLExportDetailsListing,
  })),
);

const GLExportForm = lazy(() =>
  import('./GLExportForm').then(module => ({
    default: module.GLExportForm,
  })),
);

const GLExportFormDetails = lazy(() =>
  import('./GLExportFormDetails').then(module => ({
    default: module.GLExportFormDetails,
  })),
);

const GLExportDetail = lazy(() =>
  import('./GLExportDetail').then(module => ({
    default: module.GLExportDetail,
  })),
);

const GLExportDetailsView = lazy(() =>
  import('./GLExportDetailsView').then(module => ({
    default: module.GLExportDetailsView,
  })),
);

const GLExportLedgerDetail = lazy(() =>
  import('./GLExportLedgerDetail').then(module => ({
    default: module.GLExportLedgerDetail,
  })),
);

const glExportRoutes = [
  {
    props: { exact: true, path: '/gl-interface/gl-export' },
    component: <GLExportListing />,
  },
  {
    props: { exact: true, path: '/gl-interface/gl-export-details' },
    component: <GLExportDetailsListing />,
  },
  {
    props: { exact: true, path: '/gl-interface/gl-export/add' },
    component: <GLExportForm mode="add" />,
  },
  {
    props: { exact: true, path: '/gl-interface/gl-export-details/add' },
    component: <GLExportFormDetails mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/gl-export/:glExportID/view',
    },
    component: <GLExportDetail />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/gl-export-details/:glExportID/view',
    },
    component: <GLExportDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/gl-export/ledger-detail',
    },
    component: <GLExportLedgerDetail />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/gl-export/ledger-detail/edit',
    },
    component: <GLExportLedgerDetail mode="Edit" />,
  },
];

export default glExportRoutes;

import React, { lazy } from 'react';

const GLMaintenanceForm = lazy(() =>
  import('./GLMaintenanceForm').then(module => ({
    default: module.GLMaintenanceForm,
  })),
);

const glMaintenanceRoutes = [
  {
    props: { exact: true, path: '/gl-interface/gl-maintenance' },
    component: <GLMaintenanceForm />,
  },
];

export default glMaintenanceRoutes;

import React, { lazy } from 'react';

const GLCBExportListing = lazy(() =>
  import('./GLCBExportDetailsListing').then(module => ({
    default: module.GLCBExportListing,
  })),
);

const GLCBExportDetailsForm = lazy(() =>
  import('./GLCBExportDetailsForm').then(module => ({
    default: module.GLCBExportDetailsForm,
  })),
);

const GLCBExportDetailsView = lazy(() =>
  import('./GLCBExportDetailsView').then(module => ({
    default: module.GLCBExportDetailsView,
  })),
);

const glcbExportRoutes = [
  {
    props: { exact: true, path: '/gl-interface/gl-cb-export-details' },
    component: <GLCBExportListing />,
  },
  {
    props: { exact: true, path: '/gl-interface/gl-cb-export-details/add' },
    component: <GLCBExportDetailsForm />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/gl-cb-export-details/:glcbID/view',
    },
    component: <GLCBExportDetailsView />,
  },
];

export default glcbExportRoutes;

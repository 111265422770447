import React, { lazy } from 'react';

const CompanyGlMappingListing = lazy(() =>
  import('../CompanyGLMapping/CompanyGlMappingListing').then(module => ({
    default: module.CompanyGlMappingListing,
  })),
);

const GLInterfacePolicy = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLInterfacePolicyModule/GLInterfacePolicyForm'
  ).then(module => ({ default: module.GLInterfacePolicyForm })),
);

const GLDynamicForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLTransactionModule/GLDynamic/GLDynamicForm'
  ).then(module => ({ default: module.GLDynamicForm })),
);

const GLBillExpenseForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLTransactionModule/GLBillExpense/GLBillExpenseForm'
  ).then(module => ({ default: module.GLBillExpenseForm })),
);

const ClientAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/ClientAccount/ClientAccount'
  ).then(module => ({ default: module.ClientAccount })),
);

const SubcontractorAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/SubcontractorAccount/SubcontractorAccount'
  ).then(module => ({ default: module.SubcontractorAccount })),
);

const SupplierAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/SupplierAccount/SupplierAccount'
  ).then(module => ({ default: module.SupplierAccount })),
);

const PurchaseAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/PurchaseAccount/PurchaseAccount'
  ).then(module => ({ default: module.PurchaseAccount })),
);

const POBAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/POBAccount/POBAccount'
  ).then(module => ({ default: module.POBAccount })),
);

const glMappingRoutes = [
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/company-gl-mapping',
    },
    component: <CompanyGlMappingListing />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-interface-policy`,
    },
    component: <GLInterfacePolicy mode="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-dynamic/:associationType`,
    },
    component: <GLDynamicForm mode="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-dynamic/:associationType/setup`,
    },
    component: <GLBillExpenseForm mode="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-static/client-account`,
    },
    component: <ClientAccount mode="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-static/subcon-account`,
    },
    component: <SubcontractorAccount mode="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-static/supplier-account`,
    },
    component: <SupplierAccount mode="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-static/purchase-account`,
    },
    component: <PurchaseAccount mode="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: `/company-setup/submenu/:CompanyID/company-gl-mapping/gl-static/pob-account`,
    },
    component: <POBAccount mode="company-setup" />,
  },
];

export default glMappingRoutes;

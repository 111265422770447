import React, { lazy } from 'react';
// import { UploadValidationListing } from '@contract-root/admin-react/src/containers/SystemAdminModule/UOMModule/UploadValidationListing';

const TermsConditionsListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/TermsConditionsModule/TermsConditionsListing'
  ).then(module => ({
    default: module.TermsConditionsListing,
  })),
);

const TermsConditionsForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/TermsConditionsModule/TermsConditionsForm'
  ).then(module => ({
    default: module.TermsConditionsForm,
  })),
);

const termsConditionsRoutes = [
  {
    props: { exact: true, path: '/common-settings/terms-conditions' },
    component: <TermsConditionsListing />,
  },
  {
    props: { exact: true, path: '/common-settings/terms-conditions/:type' },
    component: <TermsConditionsForm />,
  },
  {
    props: { exact: true, path: '/common-settings/terms-conditions/:ID/:type' },
    component: <TermsConditionsForm />,
  },
];

export default termsConditionsRoutes;

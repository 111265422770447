import React, { lazy } from 'react';

const SignaturePolicyListing = lazy(() =>
  import('../SignaturePolicy/SignaturePolicyListing').then(module => ({
    default: module.SignaturePolicyListing,
  })),
);
const SignaturePolicyForm = lazy(() =>
  import('../SignaturePolicy/SignaturePolicyForm').then(module => ({
    default: module.SignaturePolicyForm,
  })),
);

const signaturePolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/signature-policy',
    },
    component: <SignaturePolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/signature-policy/:type',
    },
    component: <SignaturePolicyForm />,
  },
];

export default signaturePolicyRoutes;

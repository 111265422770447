import React, { lazy } from 'react';
import cbExportRoutes from './CBExport/CBExportRoutes';
import glReportingRoutes from './DigitalReporting/GLReportingRoutes';
import glcbExportRoutes from './GLCBExport/GLCBExportDetailsRoutes';
import glExportRoutes from './GLExport/GLExportRoutes';
import glMaintenanceRoutes from './GLMaintenance/GLMaintenanceRoutes';

const GLContractListing = lazy(() =>
  import('./GLContractListing').then(module => ({
    default: module.GLContractListing,
  })),
);

const GLInterfaceSubmenu = lazy(() =>
  import('./GLInterfaceSubmenu').then(module => ({
    default: module.GLInterfaceSubmenu,
  })),
);

const glInterfaceRoutes = [
  {
    props: { exact: true, path: '/gl-interface/contract' },
    component: <GLContractListing />,
  },
  {
    props: { exact: true, path: '/gl-interface' },
    component: <GLInterfaceSubmenu />,
  },
  ...cbExportRoutes,
  ...glExportRoutes,
  ...glcbExportRoutes,
  ...glReportingRoutes,
  ...glMaintenanceRoutes,
];

export default glInterfaceRoutes;
